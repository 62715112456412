@import "styles/variables";
@import "styles/mixins";

.title {
  font-size: 18px;

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 16px;
  }
}

.panel {
  background-color: $white;
  &:global(.ant-collapse-item .ant-collapse-header) {
    padding-left: 0;
    & :global(.ant-collapse-arrow) {
      color: $black;
    }
  }
  &:global(.ant-collapse-item-active),
  &:hover {
    .title,
    &:global(.ant-collapse-item .ant-collapse-header .ant-collapse-arrow) {
      color: $teal;
    }
  }
  :global(.ant-motion-collapse-legacy-active) {
    @include transition-default(height opacity, 0.3s, cubic-bezier(0.645, 0.045, 0.355, 1), 0ms, !important);
  }
}

.image {
  object-fit: contain;

  @media screen and (max-width: $breakpoint-md) {
    display: none;
  }
}

.baseStyles {
  a,
  a:link,
  a:visited {
    color: $teal;
  }
}
